const TransactionsMappingFields = [
    "transaction_id", 
    "date", 
    "category_key", 
    "account", 
    "counterparty",
    "amount",
    "currency"
]

const OrdersMappingFields = [
    "order_id",  
    "date", 
    "country", 
    "status", 
    "shipping_method", 
    "amount", 
    "currency", 
    "purchase_frequency", 
    "items", 
    "returning_order"
];
const OrdersRequiredFields = [
    "order_id",
    "date",
    "amount", 
    "currency"
];

const TransactionsRequiredFields = [
    "date", 
    "amount", 
    "currency"
]


const TransactionsTypes = Object.freeze({
    all: "all",
    income: "income", 
    expense: "expense",
});

const TransactionsProviderFilter = Object.freeze({
    "limit": "limit",
    "transaction" : "transaction", 
});

const DataFilterScenarioTypes = Object.freeze({
    average: "average", 
    best: "best",
    worst: "worst"
});

const DataFilterPeriodTypes = Object.freeze({
    "30D" : 30,
    "3M" : 90, 
    "6M" : 180, 
    "1Y" : 365
})

const FilterLimitTypes = Object.freeze({
    "10" : 10,
    "20" : 20, 
    "50" : 50,
})

const ConnectorTypes = Object.freeze({
    SALTEDGE_PARTNERS_V1: 'saltedge',
    SALTEDGE_V6: 'saltedge_v6',
    GOOGLE_SHEET: "google_sheet",
    PLAID: "plaid",
    STRIPE: "stripe",
    MONDAY: "monday",
    XERO: "xero",
    DEMO: "demo",
});

const UserRoles = Object.freeze({
    ADMIN: 'admin',
    DEMO: 'demo',
    COMPANY_ADMIN: 'companies_admins',
});

const PublicPaths = ['terms-and-privacy'];

const DocumentFileTypes = [
    "zip",'doc','pdf','docx','pdf','ppt','pptx','xls','xlsx','txt','rtf','csv','odt','ods','odp',
];


const SettingTypes = Object.freeze({
    "sns_topic" : "sns_topic",
    "environment": "environment",
    "dashboard" : "dashboard",
    "currency" : "currency",
    "profile": "profile",
    "company_info": "company_info",
    "first_auth": "first_auth",
    "currentUser": "currentUser",
});


const Currencies = Object.freeze({
    "gbp" : "GBP",
    "usd": "USD",
    "eur" : "EUR"
});

const CurrencieSymbols = Object.freeze({
    "gbp" : "£",
    "usd": "$",
    "eur" : "€"
});

const EnvironmentTypes = Object.freeze({
    "testing" : "testing",
    "live": "live"
});

const ObjectTypes = Object.freeze({
    "transactions" : "transactions",
    "orders": "orders"
});

const WidgetTypes = Object.freeze({
    "transactions" : "transactions",
    "forecast" : "forecast",
    "cashin_card": "cashin_card",
    "cashout_card": "cashout_card",
    "cash_balance": "cash_balance",
    "burn_rate": "burn_rate",
    "forecast_arima": "forecast_arima",
    "total_orders": "total_orders",
    "aov_orders": "aov_orders",
    "revenue_orders": "revenue_orders",
});

const OrdersWidgetTypes = Object.freeze({
    "totalOrders": "totalOrders",
    "aovOrders": "aovOrders",
});

const DataFilterScenarios = Object.freeze({
    average: "average", 
    best: "best",
    worst: "worst"
});

const DataFilterGranularities = Object.freeze({
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly", 
    yearly: "yearly"
});

const DataProviderFilter = Object.freeze({
    "period": "period",
    "granularity": "granularity", 
    "transaction" : "transaction", 
    "scenario": "scenario",
    "periodRange": "periodRange",
    "country": "country",
});

const NotificationTypes = Object.freeze({
    "transactions_ready": {
        "code" : "transactions_ready",
        "message" : "The data you have requested has been synced and is ready for you to analyse"
    }
});

const CategoryTypes = Object.freeze({
    'uncategorized': "both",
    'operating_expenses': "both",
    'investments': "both",
    'borrowing': "both",
    'taxes': "both",
    'cost_of_sales': "both",
    'forecasted': "forecast",
    'stripe': "forecast",
    'saltedge': "actuals",
});

module.exports = { 
    DataFilterScenarios,
    DataFilterGranularities,
    EnvironmentTypes,
    ConnectorTypes, 
    DocumentFileTypes, 
    SettingTypes, 
    TransactionsMappingFields,
    WidgetTypes,
    OrdersWidgetTypes,
    Currencies,
    CurrencieSymbols,
    TransactionsTypes,
    TransactionsProviderFilter,
    DataFilterPeriodTypes, 
    DataProviderFilter, 
    DataFilterScenarioTypes, 
    NotificationTypes,
    FilterLimitTypes,
    OrdersMappingFields, 
    OrdersRequiredFields,
    TransactionsRequiredFields,
    ObjectTypes,
    UserRoles,
    PublicPaths,
    CategoryTypes
};

