import { Amplify } from 'aws-amplify';
// eslint-disable-next-line
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import ThemeProvider from './theme';

import { AuthProvider } from './hooks/useAuth';
import awsmobile from "./aws-exports";
import AuthWrapper from './components/AuthWrapper';

import { Provider } from 'react-redux';
import globals from './redux/globals';

Amplify.configure(awsmobile);

export default function App() {
  return (
    <Provider store={globals}>
      <HelmetProvider>
        <ThemeProvider>
          <BrowserRouter>
            <AuthProvider>
              <AuthWrapper />
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
}