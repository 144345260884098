import React, { useEffect, useState } from "react";
import {Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Stack, Dialog, DialogContent, TextField, DialogActions, Tooltip, IconButton, Popover, MenuItem, useTheme, Box, FormControl, InputLabel, OutlinedInput, InputAdornment} from "@mui/material";
import clientApi from "src/client/client";
import { useAuth } from "src/hooks/useAuth";
import variables from 'src/styles/variables.scss';
import { AddCircleOutline, CheckCircleOutlineRounded, MoreVert, Visibility, VisibilityOff } from '@mui/icons-material';
import { ConfirmationDialog } from "src/components/layout.components";
import { useSnackbar } from "src/hooks/useSnackbar";
import { ErrorCodes } from "src/shared/errors";

export function UsersManagementPage() {
    const { user } = useAuth();
    const theme = useTheme();
    const snackbar = useSnackbar();
    const [users, setUsers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogState, setDialogState] = useState({
        open: false,
        type: "", // Can be 'edit', 'delete', 'toggleEnable', 'create'
        user: null,
    });
    const [newUser, setNewUser] = useState({ email: "", password: "El3ni@12345!", givenName: "", familyName: "", companyName: "" });
    const [showPassword, setShowPassword] = useState(true);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const result = await clientApi().app.admin.users();
                setUsers(result || []);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            }
        };

        fetchUsers();
    }, []);

    const handleOpenPopover = (event, user) => {
        setAnchorEl(event.currentTarget);
        setDialogState((prev) => ({ ...prev, user }));
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openDialog = (type, user = null) => {
        setDialogState({ open: true, type, user });
        handleClosePopover();
    };

    const closeDialog = () => {
        setDialogState({ open: false, type: "", user: null });
    };

    const handleSaveEditUser = async () => {
        const { user } = dialogState;
        if (!user) return;

        const updatedUser = {
            givenName: user.givenName,
            familyName: user.familyName,
            companyName: user.companyName,
        };

        try {
            await clientApi().app.admin.updateUser(user.id, updatedUser);
            setUsers((prevUsers) =>
                prevUsers.map((u) => (u.id === user.id ? { ...u, ...updatedUser } : u))
            );
            closeDialog();
        } catch (error) {
            console.error("Failed to update user:", error);
        }
    };

    const handleDeleteUser = async () => {
        const { user } = dialogState;
        if (!user) return;

        try {
            await clientApi().app.admin.deleteUser(user.id);
            setUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
            closeDialog();
        } catch (error) {
            console.error("Failed to delete user:", error);
        }
    };

    const handleToggleEnableUser = async () => {
        const { user } = dialogState;
        if (!user) return;

        try {
            const updatedUser = { ...user, enabled: !user.enabled };
            await clientApi().app.admin.updateUser(user.id, { enabled: updatedUser.enabled });
            setUsers((prevUsers) =>
                prevUsers.map((u) => (u.id === user.id ? updatedUser : u))
            );
            closeDialog();
        } catch (error) {
            console.error("Failed to toggle user enable status:", error);
        }
    };

    const handleCreateUser = async () => {
        const passwordCriteria = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;

        if (!passwordCriteria.test(newUser.password)) {
            snackbar.openError(ErrorCodes.error_password_validation.code);
            return;
        }

        try {
            const createdUser = await clientApi().app.admin.createUser(newUser);
            setUsers((prevUsers) => [...prevUsers, createdUser]);
            setNewUser({ email: "", password: "" });
            closeDialog();
        } catch (error) {
            console.error("Failed to create user:", error);
        }
    };

    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                    <Typography sx={{ mb: "1rem", fontSize: "18px", fontWeight: 600 }}>
                        Users Management
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{ color: variables.lightGrayPrimary, border: '1px solid ' + variables.midGrayPrimary }}
                        onClick={() => openDialog("create")}
                    >
                        Create User <AddCircleOutline sx={{ color: variables.bluePrimary, ml: '0.5rem' }} />
                    </Button>
                </Stack>
                <Table
                    sx={{
                        '.MuiTableCell-root': {
                            borderBottom: '1px solid ' + variables.midGrayPrimary,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Company Name</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.givenName} {user.familyName}</TableCell>
                                <TableCell>{user.phone || "-"}</TableCell>
                                <TableCell>{user.companyName}</TableCell>
                                <TableCell>
                                    <CheckCircleOutlineRounded
                                        style={{
                                            fontSize: 20,
                                            color: user.enabled
                                                ? theme.palette.primary.main
                                                : variables.midGrayPrimary,
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(event) => handleOpenPopover(event, user)}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* Popover for Edit/Delete */}
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => openDialog("edit", dialogState.user)}>Edit Info</MenuItem>
                    <MenuItem onClick={() => openDialog("toggleEnable", dialogState.user)}>
                        {dialogState.user?.enabled ? "Disable User" : "Enable User"}
                    </MenuItem>
                    <MenuItem onClick={() => openDialog("delete", dialogState.user)} disabled>Delete User</MenuItem>
                </Popover>
            </Box>

            {/* Dialog */}
            <Dialog open={dialogState.open} onClose={closeDialog}>
                {dialogState.type === "edit" && (
                    <>
                        <Typography
                            sx={{
                                padding: "1.5rem",
                                fontSize: "20px",
                                fontWeight: 500,
                            }}
                        >
                            Edit User Info
                        </Typography>
                        <DialogContent>
                            <TextField
                                fullWidth
                                label="Given Name"
                                value={dialogState.user?.givenName || ""}
                                onChange={(e) =>
                                    setDialogState((prev) => ({
                                        ...prev,
                                        user: { ...prev.user, givenName: e.target.value },
                                    }))
                                }
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Family Name"
                                value={dialogState.user?.familyName || ""}
                                onChange={(e) =>
                                    setDialogState((prev) => ({
                                        ...prev,
                                        user: { ...prev.user, familyName: e.target.value },
                                    }))
                                }
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Company Name"
                                value={dialogState.user?.companyName || ""}
                                onChange={(e) =>
                                    setDialogState((prev) => ({
                                        ...prev,
                                        user: { ...prev.user, companyName: e.target.value },
                                    }))
                                }
                            />
                        </DialogContent>
                        <DialogActions sx={{ p: '1.5rem' }}>
                            <Button onClick={closeDialog} variant="outlined" color="error">
                                Cancel
                            </Button>
                            <Button onClick={handleSaveEditUser} variant="outlined" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </>
                )}
                {dialogState.type === "create" && (
                    <>
                        <Typography
                            sx={{
                                padding: "1.5rem",
                                fontSize: "20px",
                                fontWeight: 500,
                            }}
                        >
                            Create User
                        </Typography>
                        <DialogContent>
                            <TextField
                                fullWidth
                                label="Email"
                                value={newUser.email}
                                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                sx={{ mb: 2 }}
                            />
                            <FormControl sx={{ mb: 2, }} variant="outlined" fullWidth>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={newUser.password}
                                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={
                                                    showPassword ? 'hide the password' : 'display the password'
                                                }
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Name"
                                value={newUser.givenName}
                                onChange={(e) => setNewUser({ ...newUser, givenName: e.target.value })}
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Family Name"
                                value={newUser.familyName}
                                onChange={(e) =>
                                    setNewUser({ ...newUser, familyName: e.target.value })
                                }
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                fullWidth
                                label="Company Name"
                                value={newUser.companyName}
                                onChange={(e) =>
                                    setNewUser({ ...newUser, companyName: e.target.value })
                                }
                            />
                        </DialogContent>
                        <DialogActions sx={{ p: '1.5rem' }}>
                            <Button onClick={closeDialog} variant="outlined" color="error">
                                Cancel
                            </Button>
                            <Button onClick={handleCreateUser} variant="outlined" color="primary">
                                Create
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            {/* ConfirmationDialog for Delete and Toggle Enable */}
            <ConfirmationDialog
                open={dialogState.open && dialogState.type === "delete"}
                title="Confirm Deletion"
                description="Are you sure you want to delete this user? This action cannot be undone."
                confirmText="Delete"
                cancelText="Cancel"
                onConfirm={handleDeleteUser}
                onCancel={closeDialog}
                iconSrc="/assets/icons/ic_alert_deletion.svg"
            />

            <ConfirmationDialog
                open={dialogState.open && dialogState.type === "toggleEnable"}
                title={dialogState.user?.enabled ? "Disable User" : "Enable User"}
                description={`Are you sure you want to ${dialogState.user?.enabled ? "disable" : "enable"} this user?`}
                confirmText={dialogState.user?.enabled ? "Disable" : "Enable"}
                cancelText="Cancel"
                onConfirm={handleToggleEnableUser}
                onCancel={closeDialog}
                iconSrc="/assets/icons/ic_alert_deletion.svg"
            />
        </>
    );
}