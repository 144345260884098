import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { bgBlur } from '../../../lib/cssStyles';
import Iconify from '../../../components/iconify';
import AccountPopover from './AccountPopover';

import variables from 'src/styles/variables.scss';
import CurrencyPicker from 'src/components/CurrencyPicker';
import useResponsive from 'src/hooks/useResponsive';
import CustomSearchBar from './Searchbar';
import { useAuth } from 'src/hooks/useAuth';
import { DataProvider, useDataProvider } from 'src/hooks/useDataProvider';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import ErrorBanner from 'src/components/errorBanner';

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

const PeriodTextDisplay = () => {
  const dataProvider = useDataProvider();

  const startDate = moment(dataProvider.filter.start).format('MMM DD, YYYY');
  const startWeek = moment(dataProvider.filter.start).format('[w]WW');

  const nowDate = moment().startOf('week').format('MMM DD, YYYY');
  const nowWeek = moment().startOf('week').format('[w]WW');

  const endDate = moment(dataProvider.filter.end).format('MMM DD, YYYY');
  const endWeek = moment(dataProvider.filter.end).format('[w]WW');

  const weekStyle = {
    fontSize: '10px',
    color: variables.lightGraySecondary
  }

  const dateStyle = {
    fontSize: '12px'
  }

  const dateContainerStyle = {
    mx: '0.5rem'
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: '0.5rem' }}>
      <Box sx={dateContainerStyle}>
        <Typography sx={dateStyle}>{startDate}</Typography>
        <Typography sx={weekStyle}>{startWeek}</Typography>
      </Box>
      <Box sx={{ content: '""', width: '20px', borderBottom: '1px solid ' + variables.lightGraySecondary }}></Box>
      <Box sx={{ ...dateContainerStyle, textAlign: 'center' }}>
        <Typography sx={dateStyle}>{nowDate}</Typography>
        <Typography sx={weekStyle}>{nowWeek}</Typography>
      </Box>
      <Box sx={{ content: '""', width: '20px', borderBottom: '1px dashed ' + variables.greenPrimary }}></Box>
      <Box sx={{ ...dateContainerStyle, textAlign: 'end', color: variables.greenPrimary }}>
        <Typography sx={dateStyle}>{endDate}</Typography>
        <Typography sx={{ ...weekStyle, color: variables.greenPrimary }}>{endWeek}</Typography>
      </Box>
    </Box>
  )
}

export default function Header({ onOpenNav }) {
  const { user } = useAuth();
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');

  const NAV_WIDTH = 72;

  const HEADER_HEIGHT = 64;

  const location = useLocation(); // Get location object
  const path = location.pathname; // Access current pathname
  const excludedPaths = ['/connectors'];

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    backgroundColor: variables.darkGrayPrimary,
    boxShadow: 'none',
    top: user.isAdmin && '54px',
    opacity: '80%',
    paddingBottom: '1px',
    borderBottom: '1px solid #20242E',
    minHeight: HEADER_HEIGHT,
    flexDirection: 'row',
    zIndex: '1101px',
    backdropFilter: 'blur(2px)',
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    backgroundColor: variables.darkGrayPrimary,

    minHeight: HEADER_HEIGHT,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1.5, 0, 0),
    },
  }));

  const now = new Date();
  const dayOfWeekOptions = { weekday: 'long' };
  const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };

  const dayOfWeek = now.toLocaleDateString('en-US', dayOfWeekOptions);
  const formattedDate = now.toLocaleDateString('en-US', dateOptions);

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER_HEIGHT,
        zIndex: theme.zIndex.appBar + 1,
        top: user.isAdmin && '54px',
        paddingBottom: '1px',
        borderBottom: '1px solid #20242E',
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV_WIDTH + 1}px)`,
          height: HEADER_HEIGHT,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 1.5, 0, 0),
          },
        }}
      >

        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify
            icon="eva:menu-2-fill"
            sx={{
              color: variables.greenPrimary,
            }}
          />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          {/* <ErrorBanner></ErrorBanner> */}
          {!excludedPaths.includes(path) &&
            <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>

              {/* <CustomSearchBar></CustomSearchBar> */}
              <DataProvider>
                <PeriodTextDisplay></PeriodTextDisplay>
              </DataProvider>
              <CurrencyPicker></CurrencyPicker>

            </Box>
          }
          {!user.isAdmin &&
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ px: '1.5rem', width: '100%', textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                <Typography sx={{ fontSize: '12px', textAlign: 'right' }}>{dayOfWeek}</Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 600, textAlign: 'right' }}>{formattedDate}</Typography>
              </Box>
              <AccountPopover />
            </Box>
          }
        </Stack>
      </Toolbar>
    </AppBar>
  );
}