import { update } from "lodash";
import { request } from "./request";


export default function clientApi() {
    return {
        app: {
            connectors : {
                list: async (params) => await request.getData("connectors", params),
                create: async (params, data) => await request.postData("connectors", params, data),
                get: async (id, params) => await request.getData(`connectors/${id}`, params), 
                update: async (id, data) => await request.postData(`connectors/${id}`, {}, data),
                remove: async (id) => await request.deleteData(`connectors/${id}`, {}),

                init: async (id, params) => await request.postData(`connectors/${id}/init`, {}, params),
                connect: async (id, params) => await request.getData(`connectors/${id}/connect`, params),
                sync: async (id, params) => await request.postData(`connectors/${id}/sync`, {}, params),
                reconnect: async (id, params) => await request.postData(`connectors/${id}/reconnect`, {}, params),
           },
           sharing: {
                get: async (params) => await request.getData(`sharing`, params),
           },
           files: {
                list: async (params) => await request.getData("files", params),
                upload: async (file, params) => await request.putData("files", params, file ), 
                schema: async (params) => await request.getData("files/schema", params),
                import: async (params, data) => await request.postData("files/import", params, data),
                delete: async (params) => await request.deleteData("files", params)
           },
           settings: {
               list: async (keys, user, email) => await request.getData("settings", {keys: keys, user: user, email: email}),
               get: async (key) => await request.getData(`settings/${key}`, {}),
               set: async (key, value) => await request.postData(`settings/${key}`, {}, {value: value}),
               reset: async () => await request.postData(`settings/reset`, {})
           }, 
           admin: {
                users: async () => await request.getData("admin/users", {}),
                deleteUser: async (id) => await request.deleteData(`admin/users/${id}`, {}),
                createUser: async (params) => await request.postData(`admin/users`, {}, params),
                updateUser: async (id, params) => await request.putData(`admin/users/${id}`, params),
           }
        }, 
        data: {
            request: async (path, filter) => await request.getData(path, filter),
            prepare: async (path, filter) => await request.getData(path, filter),
            store: async (path, params) => await request.postData(path, {}, params),
        },
        transactions: {
            list: async (filter) => await request.getData(`transactions`, filter),
            remove: async (ids, filter) => await request.deleteData(`transactions/${ids}`, filter),
            update: async (ids, filter) => await request.putData(`transactions/${ids}`, filter),
        }
    }
}