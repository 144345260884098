import { useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import Page404 from './pages/Page404';
import { useAuth } from './hooks/useAuth';
import ConnectorsPage from './pages/connectors/page';
import AccountSettingsPage from './pages/account/settings';
import AccountSecurityPage from './pages/account/security';
import PerformanceKpisPage from './pages/performanceKpis/page';
import { AdminUserPage } from './pages/admin/user/page';
import CashflowPage from './pages/cashflow/page';
import FullWidthComponent from './components/FullWidthComponent';
import ImportHistoryPage from './pages/admin/import/page';
import TermsAndPrivacyPage from './pages/account/terms-and-privacy';
import LoadingPage from './pages/LoadingPage';
import { UserRoles } from './shared/enums';
import { UsersManagementPage } from './pages/admin/users/page';

export const AppRoutes = [
  {
    url: "/terms-and-privacy",
    roles: [],
    public: true,
    component: <TermsAndPrivacyPage />,
  },
  {
    url: "/overview",
    roles: [],
    component: <CashflowPage />,
  },
  {
    url: "/overview/:widgetType",
    roles: [],
    component: <FullWidthComponent />,
  },
  {
    url: "/performance-kpis",
    roles: [],
    component: <PerformanceKpisPage />,
  },
  {
    url: "/performance-kpis/:widgetType",
    roles: [],
    component: <FullWidthComponent />,
  },
  {
    url: "/profile-settings",
    roles: [],
    component: <AccountSettingsPage />,
  },
  {
    url: "/security-settings",
    roles: [],
    component: <AccountSecurityPage />,
  },
  {
    url: "/connectors",
    roles: [],
    component: <ConnectorsPage />,
  },
  {
    url: "/admin/user",
    roles: [UserRoles.ADMIN, UserRoles.DEMO],
    component: <AdminUserPage />,
  },
  {
    url: "/admin/user/import-history",
    roles: [UserRoles.ADMIN, UserRoles.DEMO],
    component: <ImportHistoryPage />,
  },
  {
    url: "/admin/user-management",
    roles: [UserRoles.ADMIN, UserRoles.DEMO, UserRoles.COMPANY_ADMIN],
    component: <UsersManagementPage />,
  },
  {
    url: "/404",
    roles: [],
    component: <Page404 />,
  },
  {
    url: "/",
    roles: [],
    component: <CashflowPage />,
  },
  {
    url: "*",
    roles: [],
    component: <Page404 />,
  },
];

export default function Router() {
  const { user, loading } = useAuth();

  const generateRoutes = () => {
    if (loading) {
      return [{ path: "*", element: <LoadingPage /> }];
    }

    const isAdmin = user?.isAdmin;
    const isCompanyAdmin = user?.isCompanyAdmin;
    const isDemo = user?.isDemo;

    // Public routes
    const publicRoutes = AppRoutes.filter(route => route.public).map(route => ({
      path: route.url,
      element: route.component,
    }));

    // Base routes for authenticated users
    const baseRoutes = AppRoutes.filter(route =>
      route.roles.length === 0 || route.roles.includes(user.role)
    ).map(route => ({
      path: route.url,
      element: route.component,
    }));

    // Admin specific routes
    const adminRoutes = AppRoutes.filter(route =>
      (route.roles.includes(UserRoles.ADMIN) && isAdmin) ||
      (route.roles.includes(UserRoles.COMPANY_ADMIN) && isCompanyAdmin) ||
      (route.roles.includes(UserRoles.DEMO) && isDemo)
    ).map(route => ({
      path: route.url,
      element: route.component,
    }));

    return [
      ...publicRoutes,
      {
        path: "/",
        element: <DashboardLayout />,
        children: [...baseRoutes, ...adminRoutes],
      },
    ];
  };

  return useRoutes(generateRoutes());
}

export function PublicRoutes() {
  const publicRoutes = AppRoutes.filter(route => route.public).map(route => ({
    path: route.url,
    element: route.component,
  }));

  return useRoutes(publicRoutes);
}

