import React from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Button,
} from "@mui/material";

export function ConfirmationDialog({
    open,
    title = "Confirmation",
    description = "Are you sure you want to continue?",
    confirmText = "Confirm",
    cancelText = "Cancel",
    onConfirm,
    onCancel,
    iconSrc,
}) {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                ".MuiDialog-paper": {
                    height: "auto",
                    p: "2rem",
                },
            }}
        >
            <Typography
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    mb: "2rem",
                    alignItems: "center",
                    fontSize: "20px",
                    fontWeight: 500,
                }}
            >
                {iconSrc && (
                    <img
                        src={iconSrc}
                        alt="icon"
                        style={{
                            width: "40px",
                            height: "40px",
                            marginBottom: "1rem",
                        }}
                    />
                )}
                {title}
            </Typography>
            <DialogContent>
                <Typography
                    id="alert-dialog-description"
                    sx={{ fontSize: "14px", mb: "2rem", textAlign: "center" }}
                >
                    {description}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                    onClick={onCancel}
                    sx={{
                        color: "gray",
                        fontWeight: 500,
                    }}
                >
                    {cancelText}
                </Button>
                <Button
                    variant="contained"
                    onClick={onConfirm}
                    autoFocus
                    sx={{
                        boxShadow: "none",
                        fontWeight: 500,
                        background: "red",
                        "&:hover": {
                            background: "darkred",
                            color: "white",
                        },
                    }}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

