import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import variables from 'src/styles/variables.scss';
import Logo from '../logo/Logo';

NavSection.propTypes = {
    data: PropTypes.array,
};

export default function NavSection({ data = [], expandNav, setExpandNav }) {

    const handleAlertIconClick = () => {
        setExpandNav(!expandNav);
    };

    return (
        <List
            disablePadding
            sx={{
                p: 2,
                position: 'fixed',
                zIndex: 9999,
            }}
        >
            {!expandNav &&
                <Box
                    sx={{
                        p: 1,
                        display: 'inline-flex',
                        '&:hover': {
                            '& .expand-btn': {
                                opacity: 1, // Show the btn on hover
                            }
                        },
                    }}
                >
                    <Logo sx={{ width: 24, height: 24 }} src={"/assets/icons/navbar/ic_logo_xs.svg"} />
                    <Box className="expand-btn" sx={{
                        p: 1,
                        m: 2,
                        width: '40px',
                        background: variables.midGrayPrimary,
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '40px',
                        alignItems: 'center',
                        borderRadius: '4px',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        cursor: 'pointer',
                    }}
                        onClick={handleAlertIconClick}
                    >
                        <Box
                            component="img"
                            src="/assets/icons/navbar/ic_toggle_open.svg"
                            sx={{
                                width: 12,
                                height: 12,
                                rotate: '180deg',
                                color: 'primary.main',
                            }}

                        />
                    </Box>
                </Box>
            }
            {expandNav &&
                <Box
                    sx={{
                        py: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Logo sx={{ width: 84, height: 24 }} src={"/assets/icons/navbar/ic_logo_lg.svg"} />
                    <Box sx={{
                        p: 1,
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '40px',
                        alignItems: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',

                        '&:hover': {
                            background: variables.midGrayPrimary,
                        }
                    }}
                        onClick={handleAlertIconClick}
                    >
                        <Box
                            component="img"
                            src="/assets/icons/navbar/ic_toggle_open.svg"
                            sx={{
                                width: 12,
                                height: 12,
                                color: 'primary.main',
                            }}

                        />
                    </Box>
                </Box>
            }

            {data.map((item) => (
                <NavItem key={item.title} item={item} expandNav={expandNav} />
            ))}
        </List>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({ item, expandNav }) {
    const { title, path, icon, navClass } = item;

    return (
        <StyledNavItem
            component={RouterLink}
            to={path}
            className={navClass}
            sx={{
                width: expandNav ? 144 : 40,
                color: variables.lightGrayPrimary,
                fontWeight: 500,
                marginTop: '1rem',
                transition: 'all 0.2s ease',
                borderRadius: '4px',
                '&.active': {
                    color: variables.greenPrimary,
                    background: variables.midGrayPrimary,
                },
                '&:hover': {
                    background: variables.midGrayPrimary,
                    zIndex: '2000',
                    width: '160px',
                    '& .nav-text': {
                        display: 'block',
                        whiteSpace: 'nowrap',
                    },
                },
                '.nav-text': {
                    display: 'none',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            }}
        >
            {!expandNav ? (
                <>
                    <StyledNavItemIcon>{icon}</StyledNavItemIcon>
                    <ListItemText className="nav-text" primary={title} />
                </>
            ) : (
                <>
                    <StyledNavItemIcon>{icon}</StyledNavItemIcon>
                    <ListItemText primary={title} />
                </>
            )}

        </StyledNavItem>
    );
}
